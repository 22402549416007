import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import tw, { css } from 'twin.macro'
import WallpaperBackgroundImage from "../components/wallpaperBackgroundImage"
import Wallpaper from "../components/wallpaper"
import Recaptcha from 'react-recaptcha'
import { useForm } from "react-hook-form"
import Button from '../components/button'
import Icon from '../components/icon'
import axios from "axios";
import {
	faSpinner,
	faCheck
} from '@fortawesome/free-solid-svg-icons'
import StickyBox from "react-sticky-box";

const emailValidator = require('email-validator')
const http = axios.create()
const windowGlobal = typeof window !== 'undefined' && window;

const Advertise = (props) => {

	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState()
	const [success, setSuccess] = useState(false)

	const { defaultBg } = useStaticQuery(
		graphql`
		  query {
			defaultBg: file(relativePath: { eq: "background.jpg" }) {
				childImageSharp {
				  fluid(quality: 90, maxWidth: 720) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				  }
				}
			  }
		  }
		`
	)

	const { register, errors, handleSubmit, setValue } = useForm()

	register({name:'g-recaptcha-response', type:'text'}, { required: true})

	const onSubmit = (data) => {
		const formData = new FormData()
		for (var key in data) {
			formData.append(key, data[key]);
		}
		setSubmitting(true)
		setError(null)
		http.post(
			'https://6b6575e4.sibforms.com/serve/MUIEALun9pm0o5rOA3FPWQmjWKixC82OMPArLhS7ODrCWbL0VuFZDfFc20dYw0uO2oDY8mUobJJV29oX5nJ0a4WdoXB5ZusIdODhZs3RR6FfCKLPtcZHzMAGx9jyCXe3AMnOxX1QJIV8T2Y94FqP3Ndh-a7oY6VN8fEpVGkOHgwYAsOfyr0fg4OKsc8NFoQXGzSk3mmmKZueUFpW?isAjax=1',
			formData
		)
			.then(result => {
				setSuccess(true)
			})
			.catch(err => {
				setError(err)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const onRecaptchaVerify = (response) => {
		setValue('g-recaptcha-response', response)
	}

	useEffect(() => {

		const script = document.createElement("script")

		script.src ="https://www.google.com/recaptcha/api.js?render=explicit"

		script.async = true
		script.defer = true

		document.body.appendChild(script)

		return () => {
			// clean up the script when the component in unmounted
			document.body.removeChild(script)
		}
		
	})

	return <>
		<WallpaperBackgroundImage
			fluid={defaultBg.childImageSharp.fluid}
		/>
		<Layout fullWidth={true}>
			<SEO title="Advertise" />
			<div tw="w-full sm:flex-grow flex items-center justify-center">
				<StickyBox offsetTop={56} tw="sm:p-6">
					<div tw="w-full">
						<form tw="sm:w-96 sm:rounded-2xl bg-white bg-opacity-90 p-6" onSubmit={handleSubmit(onSubmit)}>
							<div tw="text-2xl font-bold text-center mb-6">Advertise on file.io!</div>
							<div tw="mb-6">Please fill out the brief form below and we will be in touch shortly with details on how to get your ad campaign started.</div>
							<label htmlFor="FULLNAME">Your Name</label>
							<input
								name="FULLNAME"
								type="text"
								placeholder="First Last"
								ref={register({
									required: true
								})}
							/>
							{errors.FULLNAME && <div className="error">Please enter your name</div>}
							<label htmlFor="EMAIL">Email Address</label>
							<input
								name="EMAIL"
								type="text"
								placeholder="name@example.com"
								ref={register({
									required: true,
									validate: value => emailValidator.validate(value)
								})}
							/>
							{errors.EMAIL && <div className="error">Please enter a valid email address</div>}
							<label htmlFor="COMPANYNAME">Company Name</label>
							<input
								name="COMPANYNAME"
								placeholder="Your Company"
								type="text"
								ref={register()}
							/>
							<label htmlFor="COMPANYURL">Company Website</label>
							<input
								name="COMPANYURL"
								placeholder="https://www.example.com"
								type="text"
								ref={register()}
							/>
							{ windowGlobal && <Recaptcha
								sitekey="6Lckm2kfAAAAAOHZZih6XpmuWYAFGWI6TasriDdC"
								verifyCallback={onRecaptchaVerify}
								expiredCallback={() => setValue('g-recaptcha-response',null)}
								onloadCallback={() => {
									// console.log('recaptcha loaded')
								}}
								size="normal"
								render="explicit"
							/>}
							{errors['g-recaptcha-response'] && <div tw="mt-4"><div className="error">Please complete the CAPTCHA</div></div>}
							<label tw="inline! self-start! mb-4" htmlFor="optin">
								<input
									name="optin"
									id="optin"
									type="checkbox"
									ref={register({required: true})}
									tw="inline! w-auto! self-start! mr-2 mb-0! mt-4"
								/>
								I agree to be contacted by file.io and accept the file.io <a href="/privacy" target="top">privacy policy</a>.
							</label>
							{errors.optin && 
								<div tw="mt-4">
									<div className="error">Please accept the terms</div>
								</div>
							}
							{ error && <div tw="mt-4"><div tw="mb-0!" className="error">There was an error saving your contact details. Please review and try again.</div></div>}
							{!success && <Button 
								tw="mt-6 w-full" 
								special 
								type="submit"
								spin={submitting}
							>Contact Me</Button>}
							{success && <div tw="mt-6 bg-green bg-opacity-20 p-4 rounded-md border border-green flex">
									<Icon icon={faCheck} tw="h-12 mr-4 text-green"/>
									<div>Your contact details were saved successfully. Thank you!</div>
								</div>
							}
						</form>
					</div>
				</StickyBox>
			</div>
		</Layout>
	</>
}

export default Advertise