import React, { useContext, useEffect, useState, useMemo } from "react"
import loadable from '@loadable/component'
// import WelcomeWallpaper from "./welcomeWallpaper"
import UiStoreContext from "../contexts/uiStoreContext"
import { observer } from "mobx-react-lite"
// import { TimeoutComponent } from "./timeoutComponent"
import useWindowDimensions from "../hooks/useWindowDimensions"
import breakpoints from "../styles/breakpoints"
import mq from '../styles/media-queries'
import { useTheme } from '../hooks/useTheme'
import tw from "twin.macro"

const windowGlobal = typeof window !== 'undefined' && window;

const DefaultWallpaper = () => {

	const { setTheme } = useTheme()
	const theme = {
		nav: {
			[mq.sm] : {
				"background" : "0",
				"backgroundColor" : "rgba(0, 0, 0, 0.3)",
				a : {
					...tw`text-white! border-white`
				}	
			}
		}
	}

	useEffect(() => {
		setTheme(theme)
	}, [])
	

	return <div tw="hidden sm:block absolute w-full h-full bg-blue"></div>

}

const ShowAdsWallpaper = React.memo(({showAds, ...props}) => {

	const [adId, setAdId] = useState()
	const Ad = showAds && windowGlobal && adId && loadable(() => import(`../ads/${adId}/ad`))

	// const [fadeOut, setFadeOut] = useState(false)
	// const fadeOutWelcome = Boolean(showAds && !Ad)

	useEffect(() => {
		if (props.adId === 'venatus') {
			setAdId(props.adId)
		}
	},[])

	return <>	
		{ showAds && Ad ? <Ad {...props}/> : <DefaultWallpaper/> }
		{/* { fadeOutWelcome && <TimeoutComponent callback={() => { setFadeOut(true)}} delay={3000}/> }
		{ fadeOutWelcome && <TimeoutComponent callback={() => { setFadeOut(false); setAdId('dailymotion') }} delay={3500}/> }
		{ Ad ? <Ad fadeOut={fadeOut} {...props}/> :  <WelcomeWallpaper fadeOut={fadeOut}/>} */}
		{/* <WelcomeWallpaper/> */}
	</>

})

const Wallpaper = observer(({ children, ...props }) => {
	
	const uiStore = useContext(UiStoreContext)
	// const minWidth = breakpoints.find(bp => bp.xl).xl
	const minWidth = breakpoints.find(bp => bp.md).md
	const [minWidthMetOnce, setMinWidthMetOnce] = useState(false)
	const { width } = useWindowDimensions()

	useEffect(() => {
		if (width >= minWidth) {
			setMinWidthMetOnce(true)
		}
	}, [width])

	// return width < minWidth ? <DefaultWallpaper/> : <ShowAdsWallpaper showAds={uiStore.displayAds && minWidthMetOnce} {...props}/>

	return <DefaultWallpaper {...props}/>
})

export default Wallpaper