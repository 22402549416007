import { useState, useEffect } from 'react';

const windowGlobal = typeof window !== 'undefined' && window;

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = windowGlobal || {innerWidth:0, innerHeight:0};
	return {
		width,
		height
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		
		if (!windowGlobal) {
			return
		}

		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);

	}, []);

	return windowDimensions;
}